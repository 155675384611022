
import { defineComponent, inject } from 'vue';
import store from "../store/index";
import { Profile } from "../models/Profile";

export default defineComponent({
  name: 'Mail',
  props: {
    email: String,
    mailLists: Array,
    mailTemplates: Array
  },
  async beforeMount() {
    await store.dispatch('getEmail')
  },
  data() {
    return {
      show: "",
      updatePasswordMsg: false,
      updatePasswordPassword: "",
      updatePasswordEmail: "",
      createEmailMsg: false,
      createEmailPassword: "",
      createEmailEmail: "",
      createAliasMsg: false,
      createAliasEmail: "",
      createAliasAlias: ""
    };
  },
  computed: {
    emailProfiles(): Array<Profile> {
      return store.state.emailProfiles
    }
  },
  methods: {
    async updatePassword() {
      let res = await store.dispatch(
        "updateUserPassword",
        { password: this.updatePasswordPassword, email: this.updatePasswordEmail }
      );
      if (res) {
        this.updatePasswordMsg = true;
        this.updatePasswordPassword = "";
        this.updatePasswordEmail =  "";
      } else {
        alert("Error updating password!");
      }
    },

    async createEmailAccount() {
      let res = await store.dispatch(
        "createEmailAccount",
        { password: this.createEmailPassword, email: this.createEmailEmail }
      );
      if (res) {
        this.createEmailMsg = true;
        this.createEmailPassword = "";
        this.createEmailEmail =  "";
      } else {
        alert("Error creating email account!");
      }
    },

    async createAlias() {
      let res = await store.dispatch(
        "createAlias",
        { email: this.createAliasEmail, alias: this.createAliasAlias }
      );
      if (res) {
        this.createAliasMsg = true;
        this.createAliasEmail =  "";
        this.createAliasAlias =  "";
      } else {
        alert("Error creating alias!");
      }
    },
  },

});
